import http from '@/utils/http';
import { objectToUrlParams } from '@/utils/url'
import Axios from 'axios'; 

const payBaseUrl = process.env.NODE_ENV == "production" ? 'https://pays.melinked.com/' : 'https://tpay.melinked.com/';

// 获取钱包签名
export function getUserAuth_fetch(url, params) {
  return Axios.post(payBaseUrl + url, params)
    .then(data => {
      return data.data;
    }
    )
    .catch(e => console.log("Oops, error", e))
}

// 获取初始化信息
export function getMeChatPrompts_fetch(params, token) {
  return Axios.post(payBaseUrl + '/rest/meChat/v5/meChatPrompts', params, {
    headers: { 'Authorization': `Authorization ${token}`, 'language': localStorage.getItem("langCode") },
  }).then(data => {
    return data.data
  })
}

// 获取实际费用信息
export function getMeChatPricing_fetch(params, token) {
  return Axios.post(payBaseUrl + '/rest/meChat/v5/meChatPricing', params, {
    headers: { 'Authorization': `Authorization ${token}`, 'language': localStorage.getItem("langCode") },
  }).then(data => {
    return data.data
  })
}

// mechat购买
export function meChatRecharge_fetch(params, token) {
  return Axios.post(payBaseUrl + '/rest/meChat/v5/meChatRecharge', params, {
    headers: { 'Authorization': `Authorization ${token}`, 'language': localStorage.getItem("langCode") },
  }).then(data => {
    return data.data
  })
}
