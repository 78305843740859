<template>
  <div class="paymentModule">
    <div
      class="moduleHeader"
      :style="{ 'margin-top': componentIndex == 1 ? '38px' : '20px' }"
    >
      <span
        v-if="componentIndex == 2"
        class="iconStyle icon-left iconfont_Me left"
        @click.stop="$emit('changeComponentIndex', 1)"
      ></span>
      <span class="titleStyle" v-if="componentIndex == 1">{{
        replaceMechati18n($t("buyMeChat"))
      }}</span>
      <span class="titleStyle" v-if="componentIndex == 3">{{
        $t("confirmPayment")
      }}</span>
      <span
        class="iconStyle icon-x iconfont_Me right"
        @click.stop="$emit('close')"
      ></span>
    </div>
    <div class="scrollBox" v-if="componentIndex == 1">
      <div class="moduleContainer">
        <div class="selector">
          <div class="box" @click="selectCNY">
            <el-radio v-model="currencyRadio" label="CNY"> </el-radio>
            <div class="flex">
              <el-image
                style="width: 42px; height: 42px"
                :src="require('../../../assets/images/pay/china.png')"
                fit="fill"
              ></el-image>
              CNY
            </div>
          </div>
          <div class="box" @click="selectUSD">
            <el-radio v-model="currencyRadio" label="USD"> </el-radio>
            <div class="flex">
              <el-image
                style="width: 42px; height: 42px"
                :src="require('../../../assets/images/pay/usa.png')"
                fit="fill"
              ></el-image>
              USD
            </div>
          </div>
        </div>
        <div class="productDetails">
          {{ decodeData && decodeData.fixing ? decodeData.fixing : "" }}
        </div>
        <div class="amountSelection">
          <div
            class="selectItem"
            v-for="(item, index) in amounts"
            :key="index"
            v-waves
            @click.stop="selectAmount(item, index)"
            :class="{
              active: item.active && item.text != 0 && !item.expand,
              expand: item.expand,
            }"
          >
            <span v-if="item.type == 0">
              {{ `${item.text} ${robotInfo.prompts}` }}
            </span>
            <div v-if="item.type == 1" class="selectButton">
              <span
                class="iconfont_Me icon-a-zu10330 iconStyle"
                v-if="!item.expand"
              ></span>
              <span>
                {{ item.text == 0 ? $t("other") : item.text }}
                {{ `${item.text != 0 ? robotInfo.prompts : ""}` }}
              </span>
            </div>
            <div
              v-if="item.expand"
              class="itemList"
              v-clickoutside="closeItemList"
            >
              <div
                class="item"
                v-for="num in item.nums"
                :key="num"
                @click.stop="selectNum(num)"
              >
                {{ `${num} ${robotInfo.prompts}` }}
              </div>
              <div class="iconItem" @click.stop="closeItemList">
                <span class="iconfont_Me icon-a-zu10330 iconStyle"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 选址支付方式 -->
        <div class="pay-method">
          <!-- 余额 -->
          <div class="balance-pay" @click="selectYE" v-waves>
            <div class="left">
              <el-image
                style="width: 25px;"
                :src="require('../../../assets/images/pay/ye.png')"
                fit="fill"
              >
              </el-image>
              {{ $t("balance") }}：
              <span v-if="currencyRadio == 'CNY'">￥{{ rmbassetsAbled }}</span>
              <span v-if="currencyRadio == 'USD'">${{ dolarassetsAbled }}</span>
              <span class="handling">
                {{ $t("handling_charge") }}：
                <span>
                  {{ decodeData.melinked }}
                </span>
              </span>
            </div>
            <el-radio v-model="paymentRadio" label="YE"> </el-radio>
          </div>
          <div class="line"></div>
          <div
            class="cny"
            v-if="currencyRadio == 'USD'"
            @click="selectPP"
            v-waves
          >
            <div class="left">
              <el-image
                style="width: 95px;"
                :src="require('../../../assets/images/pay/pp.png')"
                fit="fill"
              >
              </el-image>
              <span class="handling">
                {{ $t("handling_charge") }}：
                <span v-if="currencyRadio == 'USD'">
                  {{ decodeData.paypal }}
                </span>
              </span>
            </div>
            <el-radio v-model="paymentRadio" label="PP"> </el-radio>
          </div>
          <!-- 微信 -->
          <!-- <div
            class="cny"
            v-if="currencyRadio == 'CNY'"
            @click="selectWX"
            v-waves
          >
            <div class="left">
              <el-image
                style="width: 26px;"
                :src="require('../../../assets/images/pay/weipay.png')"
                fit="fill"
              >
              </el-image>
              <span class="handling">
                {{ $t("handling_charge") }}：
                <span v-if="currencyRadio == 'CNY'">
                  {{ decodeData.wechat }}
                </span>
              </span>
            </div>
            <el-radio v-model="paymentRadio" label="WX"> </el-radio>
          </div>
          <div class="line" v-if="currencyRadio == 'CNY'"></div> -->
          <!-- 支付宝 -->
          <div
            class="usd"
            v-if="currencyRadio == 'CNY'"
            @click="selectZFB"
            v-waves
          >
            <div class="left">
              <el-image
                style="width: 26px;"
                :src="require('../../../assets/images/pay/zfpay.png')"
                fit="fill"
              >
              </el-image>
              <span class="handling">
                {{ $t("handling_charge") }}：
                <span v-if="currencyRadio == 'CNY'">
                  {{ decodeData.al_proportion }}
                </span>
              </span>
            </div>
            <el-radio v-model="paymentRadio" label="ZFB"> </el-radio>
          </div>
        </div>
        <!-- 真实交易金额 -->
        <div class="realAmount">
          <div class="total">
            <span>{{ $t("total") }}:</span>
            <span class="content" v-if="realPricing">
              {{
                `${currencyRadio == "CNY" ? "￥" : "$"}${realPricing.pricing}`
              }}
            </span>
          </div>
          <div class="charges" v-if="meChatPricing">
            <span>{{ $t("handling_charge") }}:</span>
            <span class="content" v-if="realPricing">
              {{ realPricing.brokerage }}
            </span>
          </div>
        </div>
        <div class="footer">
          <div
            class="nextStep"
            :class="meChatPricing ? 'active' : 'default'"
            v-waves
            @click.stop="nextStep"
          >
            <span class="iconfont_Me icon-tubiaozhizuomoban iconStyle"></span>
          </div>
        </div>
        <div class="agreementBox">
          {{ `(${$t("agreeRobotAgreement")})` }}
          <el-image
            style="width: 12px; height: 12px; margin-left: 6px;"
            :src="require('../../../assets/images/meChat/wenhao.png')"
            :fit="'fill'"
            @click.stop="readAgreement"
          ></el-image>
        </div>
      </div>
    </div>
    <div class="finalConfirmation" v-if="componentIndex == 2">
      <div class="title">
        {{ robotInfo.prompts }}
      </div>
      <div class="prompts">
        {{ selectedPrompts }}
      </div>
      <div class="title">
        {{ $t("paymentMethod") }}
      </div>
      <div class="paymentIcon">
        <el-image
          :style="{ width: payMethod == 7 ? '95px' : '26px' }"
          :src="paymentIcon"
          fit="fill"
        >
        </el-image>
        <span class="paymentTips" v-if="payMethod == 2">{{
          $t("balance")
        }}</span>
      </div>
      <div class="title" v-if="payMethod != 2">
        {{ $t("handling_charge") }}
      </div>
      <div class="charges" v-if="payMethod != 2 && realPricing">
        {{ realPricing.brokerage }}
      </div>
      <div class="line"></div>
      <div class="bigTitle">{{ $t("total") }}:</div>
      <div class="totalContent" v-if="realPricing">
        {{ `${currencyRadio == "CNY" ? "￥" : "$"}${realPricing.pricing}` }}
      </div>
      <div
        class="confirm"
        v-waves
        @click.stop="confirm"
        :style="{ margin: payMethod == 2 ? '80px auto' : '40px auto' }"
      >
        <span class="iconfont_Me icon-tubiaozhizuomoban iconStyle"></span>
      </div>
      <form
        ref="payment"
        class
        :action="payParams.submitUrl"
        method="post"
        target="_blank"
        hidden
      >
        <input
          type="text"
          :name="key"
          v-for="(val, key, index) in payParams"
          :value="val"
          :key="index"
        />
      </form>
    </div>
    <div class="confirmPayment" v-if="componentIndex == 3">
      <PaymentConfirmation @confirm="pay_YE"></PaymentConfirmation>
    </div>
  </div>
</template>
<script>
import {
  getUserAuth_fetch,
  getMeChatPrompts_fetch,
  getMeChatPricing_fetch,
  meChatRecharge_fetch,
} from "@/api/mepay/rest";
import { getAccountBalance_fetch } from "@/api/newVersion/vip";
import PaymentConfirmation from "@/newVersion/components/accountSecurityDialog/components/paymentConfirmation.vue";
import * as RESTApi from "@/api/rest";
import QRCode from "qrcode";
export default {
  name: "PaymentModule",
  components: {
    PaymentConfirmation,
  },
  props: {
    componentIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currencyRadio: "CNY",
      paymentRadio: "", //YE ZFB WX PP
      dolarassetsAbled: 0, //美元余额
      rmbassetsAbled: 0, //人民币余额
      payMethod: 0, //支付方式
      amounts: [
        {
          text: 100,
          type: 0,
          active: false,
        },
        {
          text: 200,
          type: 0,
          active: false,
        },
        {
          text: 300,
          type: 0,
          active: false,
        },
        {
          text: 0,
          type: 1,
          active: false,
          expand: false,
          nums: [],
        },
      ],
      selectedPrompts: 0,
      decodeData: {},
      realPricing: null,
      payParams: {},
    };
  },
  watch: {
    currencyRadio: {
      async handler(val, old) {
        if (val != old) {
          await this.getMeChatPrompts();
          await this.checkBalance();
          this.clearActive();
          this.amounts[3].text = 0;
        }
      },
      deep: true,
    },
    meChatPricing: {
      async handler(val, old) {
        if (val && val != old) {
          await this.getMeChatPricing();
        }
      },
      deep: true,
    },
    paymentRadio: {
      async handler(val, old) {
        if (old != "" && val != old && this.meChatPricing) {
          await this.getMeChatPricing();
        }
      },
      deep: true,
    },
    selectedPrompts: {
      async handler(val, old) {
        if (old != 0 && val != old && this.meChatPricing) {
          await this.getMeChatPricing();
        } else {
          this.realPricing = null;
        }
      },
      deep: true,
    },
  },
  computed: {
    robotInfo() {
      return this.$store.state.robot.robotInfo;
    },
    meChatPricing() {
      return this.selectedPrompts != 0 && this.paymentRadio != "";
    },
    paymentIcon() {
      let icon;
      switch (this.payMethod) {
        case 2:
          icon = require("../../../assets/images/pay/ye.png");
          break;
        case 3:
          icon = require("../../../assets/images/pay/zfpay.png");
          break;
        case 5:
          icon = require("../../../assets/images/pay/weipay.png");
          break;
        case 7:
          icon = require("../../../assets/images/pay/pp.png");
          break;
      }
      return icon;
    },
  },
  methods: {
    // 确认
    confirm() {
      if (this.payMethod === 2) {
        this.$emit("changeComponentIndex", 3);
      }
      // 微信支付
      if (this.payMethod === 5) {
        this.pay_WX();
      }
      // 支付宝支付
      if (this.payMethod === 3) {
        this.pay_ZFB();
      }
      // paypal支付
      if (this.payMethod === 7) {
        this.pay_PP();
      }
    },
    async pay_YE(pwd) {
      this.pay(pwd).then(async (decodeData) => {});
    },
    async pay_WX() {
      this.pay().then((decodeData) => {
        this.payParams = decodeData[0];
        this.granterQRcode(this.payParams.code_url, this.payParams.rechargeId);
      });
    },
    granterQRcode(text, rechargeId) {
      QRCode.toDataURL(text)
        .then((url) => {
          this.$router.push({
            name: "payResultNotify",
            query: {
              QRcodeUrl: url,
              money: this.realPricing.pricing,
              rechargeId: rechargeId,
            },
          });
          this.$store.commit("setRobotChatDescription", {
            visible: false,
          });
          this.$store.commit("setIMWindowSize", "mini");
        })
        .catch((error) => {
          this.$message({
            type: "error",
            center: true,
            message: this.$t("amountErrorTip"),
          });
        });
    },
    async pay_ZFB() {
      this.pay().then((decodeData) => {
        this.payParams = decodeData[0];
        this.$nextTick(function() {
          this.$refs.payment.submit();
        });
      });
    },
    async pay_PP() {
      this.pay().then((decodeData) => {
        this.payParams = decodeData[0];
        this.payParams.submitUrl = decodeData[0].paypalUrl;
        this.$nextTick(function() {
          this.$refs.payment.submit();
        });
      });
    },
    pay(pwd) {
      return new Promise(async (resolve, reject) => {
        if (!this.realPricing) {
          reject();
        }
        let params = {
          amount: this.realPricing.pricing,
          walletType: this.currencyRadio === "CNY" ? 1 : 2,
          payMethod: this.payMethod,
          meChatPricingId: this.realPricing.id,
          nums: this.selectedPrompts,
        };
        if (this.payMethod == 2) {
          params.password = pwd;
        }
        if (localStorage.getItem("aesEncryptPrivateKey")) {
          params.privateKey = localStorage.getItem("aesEncryptPrivateKey");
        }
        if (localStorage.getItem("publicKey")) {
          params.publicKey = localStorage.getItem("publicKey");
        }
        let result = null;
        const body = this.getPostParams(
          params,
          this.$store.state.auth.auth.randomKey
        );
        result = await meChatRecharge_fetch(
          body,
          this.$store.state.auth.auth.token
        );
        if (result.code === 200) {
          switch (this.payMethod) {
            case 2:
              resolve();
              break;
            case 3:
              resolve(JSON.parse(Base64.decode(result.data)));
              break;
            case 5:
              resolve(JSON.parse(Base64.decode(result.data)));
              break;
            case 7:
              resolve(JSON.parse(Base64.decode(result.data)));
              break;
            default:
              break;
          }
        } else if (result.code === 2001) {
          this.$message({
            type: "error",
            message: result.message,
          });
          this.$emit("changeComponentIndex", 1);
          this.$nextTick(() => {
            this.clearActive();
            this.currencyRadio = "CNY";
            this.paymentRadio = "";
            this.realPricing = null;
          });
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
      });
    },
    // 阅读协议
    async readAgreement() {
      const result = await RESTApi.getRobotInfo();
      if (result.code == 200) {
        result.data.data.isRobotChat = true;
        result.data.data.id = result.data.data.robotId;
        this.$store.commit("setRobotInfo", result.data.data);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.$store.commit("setAgrementForMechat", true);
    },
    // 进入下一步
    nextStep() {
      if (!this.meChatPricing) {
        return;
      }
      if (!this.realPricing) {
        return;
      }
      // 余额支付
      if (this.payMethod === 2) {
        // 余额不足，提示去充值
        if (
          this.currencyRadio === "CNY" &&
          this.rmbassetsAbled - this.realPricing.pricing < 0
        ) {
          this.$store.commit("setPayErrorTipsStatus", true);
          return;
        }
        // 余额不足，提示去充值
        if (
          this.currencyRadio === "USD" &&
          this.dolarassetsAbled - this.realPricing.pricing < 0
        ) {
          this.$store.commit("setPayErrorTipsStatus", true);
          return;
        }
        //这里判断是否设置了支付密码，如何没有，提示去设置支付密码
        this.checkPaymentPassword(() => {
          this.$emit("changeComponentIndex", 2);
        });
      } else {
        this.$emit("changeComponentIndex", 2);
      }
    },
    // 获取实际费用
    async getMeChatPricing() {
      switch (this.paymentRadio) {
        case "YE":
          this.payMethod = 2;
          break;
        case "ZFB":
          this.payMethod = 3;
          break;
        case "WX":
          this.payMethod = 5;
          break;
        case "PP":
          this.payMethod = 7;
          break;
      }
      let params = {
        walletType: this.currencyRadio == "CNY" ? 1 : 2,
        prompts: this.selectedPrompts,
        payMethod: this.payMethod,
      };
      const body = this.getPostParams(
        params,
        this.$store.state.auth.auth.randomKey
      );
      let result = await getMeChatPricing_fetch(
        body,
        this.$store.state.auth.auth.token
      );
      if (result.code == 200) {
        let realPricing = JSON.parse(Base64.decode(result.data));
        this.realPricing = realPricing;
      } else {
        this.paymentRadio = "";
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 获取新的token
    async handlerGetAuth() {
      let params = {
        userName: this.$store.getters.userInfo.id,
        password: this.$store.getters.userInfo.token,
      };
      let result = null;
      let base64Str = Base64.encode(JSON.stringify(params));
      let formData = new FormData();
      formData.append("user", base64Str);
      result = await getUserAuth_fetch("auth", formData);
      this.$store.commit("setAuth", result);
      return result;
    },
    // 获取余额信息
    async checkBalance() {
      let formData = new FormData();
      formData.append("token", this.$store.state.auth.auth.token);
      let result = await getAccountBalance_fetch(
        formData,
        this.$store.state.auth.auth.token
      );
      if (result.code == 200) {
        let res = JSON.parse(Base64.decode(result.data));
        this.rmbassetsAbled = res[1].assetsAbled;
        this.dolarassetsAbled = res[2].assetsAbled; //美元余额
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 获取配置信息
    async getMeChatPrompts() {
      let auth = null;
      if (this.$store.state.auth.auth && this.$store.state.auth.auth.token) {
        auth = this.$store.state.auth.auth;
      } else {
        auth = await this.handlerGetAuth();
      }
      let params = {
        walletType: this.currencyRadio == "CNY" ? 1 : 2,
      };
      const body = this.getPostParams(params, auth.randomKey);
      let result = await getMeChatPrompts_fetch(body, auth.token);
      if (result.code == 200) {
        let decodeData = JSON.parse(Base64.decode(result.data));
        this.init(decodeData);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 初始化
    init(decodeData) {
      const nums = decodeData.nums;
      const d = nums.splice(0, 3);
      d.forEach((item, index) => {
        this.amounts[index].text = item;
      });
      this.amounts[3].nums = nums;
      this.decodeData = decodeData;
    },
    closeItemList() {
      this.amounts[3].expand = false;
      this.changeSelectedPrompts();
    },
    clearActive() {
      this.amounts = this.amounts.map((amount) => {
        amount.active = false;
        return amount;
      });
      this.changeSelectedPrompts();
    },
    selectNum(num) {
      this.clearActive();
      this.amounts[3].expand = false;
      this.amounts[3].text = num;
      this.$nextTick(() => {
        this.amounts[3].active = true;
        this.changeSelectedPrompts();
      });
    },
    selectAmount(item, index) {
      if (item.type == 1) {
        this.amounts[index].expand = true;
      }
      if (item.active) {
        return;
      }
      this.clearActive();
      this.amounts[index].active = true;
      if (item.type == 0) {
        this.changeSelectedPrompts();
      }
    },
    changeSelectedPrompts() {
      const current = this.amounts.filter((amount) => amount.active)[0];
      this.selectedPrompts = current ? current.text : 0;
    },
    selectCNY() {
      this.currencyRadio = "CNY";
      this.paymentRadio = "";
    },
    selectUSD() {
      this.currencyRadio = "USD";
      this.paymentRadio = "";
    },
    selectYE() {
      this.paymentRadio = "YE";
    },
    selectPP() {
      this.paymentRadio = "PP";
    },
    selectWX() {
      this.paymentRadio = "WX";
    },
    selectZFB() {
      this.paymentRadio = "ZFB";
    },
  },
};
</script>
<style lang="stylus" scoped>
.paymentModule
  width: 100%;
  overflow: hidden;
  background: linear-gradient(180deg,#fff3df, #f5f5f5 100%, #f5f5f5 100%);
  .moduleHeader
    width: 100%;
    height: 28px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .titleStyle
      color: #000000;
      font-size: 20px;
    .iconStyle
      color: #000000;
      font-size: 20px;
      cursor pointer;
    .left
      position: absolute;
      left: 20px;
    .right
      position: absolute;
      right: 20px;
  .confirmPayment
    width: 100%;
    height: 307px;
    padding: 40px 90px 0 90px;
    box-sizing: border-box;
  .finalConfirmation
    width: 100%;
    height: 400px;
    padding-top: 1px;
    .confirm
      width: 240px;
      height: 34px;
      text-align: center;
      border-radius: 5px;
      line-height: 34px;
      font-size: 15px;
      cursor pointer;
      font-weight: 700;
      user-select none;
      background: #33CC66;
      .iconStyle
        font-size: 20px;
        color:#FFFFFF
    .bigTitle
      width: 100%;
      font-size: 14px;
      color: #999999;
      box-sizing: border-box;
      padding 0 90px;
      cursor: default;
    .totalContent
      font-size: 16px;
      color: #000000;
      margin-top: 7px;
      box-sizing: border-box;
      padding 0 90px;
    .title
      width: 100%;
      font-size: 12px;
      color: #999999;
      box-sizing: border-box;
      padding 0 90px;
      cursor: default;
      margin-top: 20px
    .prompts
      width: 100%;
      box-sizing: border-box;
      padding 0 90px;
      font-size: 14px;
      color: #000000;
      margin-top: 7px
    .paymentIcon
      width: 100%;
      box-sizing: border-box;
      padding 0 90px;
      overflow hidden
      margin-top: 7px
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .paymentTips
        margin-left: 10px;
    .charges
      width: 100%;
      box-sizing: border-box;
      padding 0 90px;
      font-size: 12px;
      color: #999999;
      margin-top: 7px
    .line
      width: calc(100% - 80px);
      height: 1px;
      background: #F0F0F0;
      margin: 15px auto 10px auto;
  .scrollBox
    width: 100%;
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar
      width 6px;
    &::-webkit-scrollbar-thumb
      border-radius: 3px;
      box-shadow: inset 0 0 5px rgba(100,100,100,0.5);
      background: rgba(100,100,100,0.4);
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
      border-radius: 0;
      background: rgba(0,0,0,0.2);
  .moduleContainer
    width: 396px;
    margin: 0 auto 40px auto;
    padding-top: 20px;
    .selector
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding 0 10px;
      .box
        cursor: pointer;
        display: flex;
        align-items: center;
      .flex
        display: flex;
        align-items: center;
      .el-radio
        margin-right: 10px;
      :deep .el-radio__label
        display: none;
      :deep .el-radio__inner
        width: 18px;
        height: 18px;
        &::after
          color: #fff;
          width: 0 !important;
          height: 0 !important;
          font-size: 14px;
          top: 4% !important;
          left: 10% !important;
          font-family: "element-icons" !important;
          content: "\E6DA";
    .productDetails
      width: 100%;
      border-radius: 11px;
      background: #FFFFFF;
      margin-top: 20px;
      box-sizing: border-box;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      color: #000000;
    .amountSelection
      width: 100%;
      border-radius: 11px;
      background: #FFFFFF;
      margin-top: 20px;
      box-sizing: border-box;
      padding: 28px 58px 8px 78px;
      position relative;
      .expand
        height: auto !important;
        min-height: 60px !important;
        overflow: hidden !important;
        position absolute !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        flex-direction: column !important;
        z-index 10 !important;
        left: 208px;
        top: 108px;
      .selectItem
        width: 110px;
        height: 60px;
        cursor: pointer;
        background: #f7f7f7;
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        margin-right: 20px;
        margin-bottom: 20px;
        box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.10);
        color: #000000;
        font-size: 15px;
        transition: height 0.5s;
        .itemList
          width: 100%;
          overflow: hidden;
          max-height: 300px;
          overflow-x: hidden;
          overflow-y: auto;
          &::-webkit-scrollbar
            width 4px;
          &::-webkit-scrollbar-thumb
            border-radius: 2px;
            box-shadow: inset 0 0 5px rgba(100,100,100,0.5);
            background: rgba(100,100,100,0.4);
          &::-webkit-scrollbar-track
            box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
            border-radius: 0;
            background: rgba(0,0,0,0.2);
          .item
            width: 100%;
            min-height: 29px;
            max-height: 46px;
            padding: 6px 0;
            box-sizing: border-box;
            line-height: 17px;
            font-size: 14px;
            color #000000;
            text-align: center;
          .iconItem
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            .iconStyle
              font-size: 10px;
              color #D9D9D9;
              transform: rotate(180deg)
        .selectButton
          width: 100%;
          height: 60px;
          flex-shrink: 0;
          position relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconStyle
            font-size: 10px;
            color #D9D9D9;
            position: absolute;
            bottom: 3px;
            left: 50%;
            transform: translate3d(-50%,0,0);
      .active
        background: #d9f6e0;
        border: 1px solid #33cc66;
        box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.10);
        .iconStyle
          color #33cc66 !important;
    .pay-method
      width: 100%;
      border-radius: 11px;
      background: #FFFFFF;
      box-sizing: border-box;
      overflow: hidden;
      margin-top: 20px;
      .balance-pay,
      .cny,
      .usd
        cursor: pointer;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 0 16px;
        box-sizing: border-box;
        .handling
          color #CFCFCF;
          font-size: 12px;
          padding-left: 10px;
        .left
          display: flex;
          align-items: center;
          .el-image
            margin-right: 8px;
      .line
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
    .realAmount
      width: 100%
      border-radius: 11px;
      background: #FFFFFF;
      box-sizing: border-box;
      overflow: hidden;
      margin-top: 20px;
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-left: 16px;
      .total
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        .content
          margin-left: 10px;
      .charges
        font-size: 12px;
        line-height: 12px;
        color: #666666;
        margin-top: 5px;
        .content
          margin-left: 7px;
    .footer
      width: 100%;
      height: 34px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      .nextStep
        width: 240px;
        height: 34px;
        margin-top: 10px;
        text-align: center;
        border-radius: 5px;
        line-height: 34px;
        font-size: 15px;
        cursor pointer;
        font-weight: 700;
        user-select none;
        .iconStyle
          font-size: 20px;
          color:#FFFFFF
      .active
        color: #FFFFFF;
        background: #33CC66;
      .default
        color: #666666;
        background: #D9D9D9;
    .agreementBox
      width: 100%;
      height: 14px;
      margin-top: 8px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #000000;
      .iconStyle
        cursor pointer;
        margin-left: 2px;
        font-size: 9px;
        vertical-align: -0.15em;
:deep .el-radio__label
  display: none;
:deep .el-radio
  margin-right: 0 !important;
:deep .el-radio__inner
  width: 18px;
  height: 18px;
  &::after
    color: #fff;
    width: 0 !important;
    height: 0 !important;
    font-size: 14px;
    top: 4% !important;
    left: 10% !important;
    font-family: "element-icons" !important;
    content: "\E6DA";
</style>
