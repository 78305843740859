var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paymentModule" }, [
    _c(
      "div",
      {
        staticClass: "moduleHeader",
        style: { "margin-top": _vm.componentIndex == 1 ? "38px" : "20px" }
      },
      [
        _vm.componentIndex == 2
          ? _c("span", {
              staticClass: "iconStyle icon-left iconfont_Me left",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit("changeComponentIndex", 1)
                }
              }
            })
          : _vm._e(),
        _vm.componentIndex == 1
          ? _c("span", { staticClass: "titleStyle" }, [
              _vm._v(_vm._s(_vm.replaceMechati18n(_vm.$t("buyMeChat"))))
            ])
          : _vm._e(),
        _vm.componentIndex == 3
          ? _c("span", { staticClass: "titleStyle" }, [
              _vm._v(_vm._s(_vm.$t("confirmPayment")))
            ])
          : _vm._e(),
        _c("span", {
          staticClass: "iconStyle icon-x iconfont_Me right",
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.$emit("close")
            }
          }
        })
      ]
    ),
    _vm.componentIndex == 1
      ? _c("div", { staticClass: "scrollBox" }, [
          _c("div", { staticClass: "moduleContainer" }, [
            _c("div", { staticClass: "selector" }, [
              _c(
                "div",
                { staticClass: "box", on: { click: _vm.selectCNY } },
                [
                  _c("el-radio", {
                    attrs: { label: "CNY" },
                    model: {
                      value: _vm.currencyRadio,
                      callback: function($$v) {
                        _vm.currencyRadio = $$v
                      },
                      expression: "currencyRadio"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "42px", height: "42px" },
                        attrs: {
                          src: require("../../../assets/images/pay/china.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v("\n            CNY\n          ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box", on: { click: _vm.selectUSD } },
                [
                  _c("el-radio", {
                    attrs: { label: "USD" },
                    model: {
                      value: _vm.currencyRadio,
                      callback: function($$v) {
                        _vm.currencyRadio = $$v
                      },
                      expression: "currencyRadio"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "42px", height: "42px" },
                        attrs: {
                          src: require("../../../assets/images/pay/usa.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v("\n            USD\n          ")
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "productDetails" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.decodeData && _vm.decodeData.fixing
                      ? _vm.decodeData.fixing
                      : ""
                  ) +
                  "\n      "
              )
            ]),
            _c(
              "div",
              { staticClass: "amountSelection" },
              _vm._l(_vm.amounts, function(item, index) {
                return _c(
                  "div",
                  {
                    directives: [{ name: "waves", rawName: "v-waves" }],
                    key: index,
                    staticClass: "selectItem",
                    class: {
                      active: item.active && item.text != 0 && !item.expand,
                      expand: item.expand
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.selectAmount(item, index)
                      }
                    }
                  },
                  [
                    item.type == 0
                      ? _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(`${item.text} ${_vm.robotInfo.prompts}`) +
                              "\n          "
                          )
                        ])
                      : _vm._e(),
                    item.type == 1
                      ? _c("div", { staticClass: "selectButton" }, [
                          !item.expand
                            ? _c("span", {
                                staticClass:
                                  "iconfont_Me icon-a-zu10330 iconStyle"
                              })
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.text == 0 ? _vm.$t("other") : item.text
                                ) +
                                "\n              " +
                                _vm._s(
                                  `${
                                    item.text != 0 ? _vm.robotInfo.prompts : ""
                                  }`
                                ) +
                                "\n            "
                            )
                          ])
                        ])
                      : _vm._e(),
                    item.expand
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "clickoutside",
                                rawName: "v-clickoutside",
                                value: _vm.closeItemList,
                                expression: "closeItemList"
                              }
                            ],
                            staticClass: "itemList"
                          },
                          [
                            _vm._l(item.nums, function(num) {
                              return _c(
                                "div",
                                {
                                  key: num,
                                  staticClass: "item",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.selectNum(num)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        `${num} ${_vm.robotInfo.prompts}`
                                      ) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "iconItem",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.closeItemList.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "iconfont_Me icon-a-zu10330 iconStyle"
                                })
                              ]
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "pay-method" }, [
              _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "balance-pay",
                  on: { click: _vm.selectYE }
                },
                [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c("el-image", {
                        staticStyle: { width: "25px" },
                        attrs: {
                          src: require("../../../assets/images/pay/ye.png"),
                          fit: "fill"
                        }
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("balance")) +
                          "：\n            "
                      ),
                      _vm.currencyRadio == "CNY"
                        ? _c("span", [
                            _vm._v("￥" + _vm._s(_vm.rmbassetsAbled))
                          ])
                        : _vm._e(),
                      _vm.currencyRadio == "USD"
                        ? _c("span", [
                            _vm._v("$" + _vm._s(_vm.dolarassetsAbled))
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "handling" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("handling_charge")) +
                            "：\n              "
                        ),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.decodeData.melinked) +
                              "\n              "
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c("el-radio", {
                    attrs: { label: "YE" },
                    model: {
                      value: _vm.paymentRadio,
                      callback: function($$v) {
                        _vm.paymentRadio = $$v
                      },
                      expression: "paymentRadio"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "line" }),
              _vm.currencyRadio == "USD"
                ? _c(
                    "div",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "cny",
                      on: { click: _vm.selectPP }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "95px" },
                            attrs: {
                              src: require("../../../assets/images/pay/pp.png"),
                              fit: "fill"
                            }
                          }),
                          _c("span", { staticClass: "handling" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("handling_charge")) +
                                "：\n              "
                            ),
                            _vm.currencyRadio == "USD"
                              ? _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.decodeData.paypal) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c("el-radio", {
                        attrs: { label: "PP" },
                        model: {
                          value: _vm.paymentRadio,
                          callback: function($$v) {
                            _vm.paymentRadio = $$v
                          },
                          expression: "paymentRadio"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currencyRadio == "CNY"
                ? _c(
                    "div",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "usd",
                      on: { click: _vm.selectZFB }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "left" },
                        [
                          _c("el-image", {
                            staticStyle: { width: "26px" },
                            attrs: {
                              src: require("../../../assets/images/pay/zfpay.png"),
                              fit: "fill"
                            }
                          }),
                          _c("span", { staticClass: "handling" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("handling_charge")) +
                                "：\n              "
                            ),
                            _vm.currencyRadio == "CNY"
                              ? _c("span", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.decodeData.al_proportion) +
                                      "\n              "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ],
                        1
                      ),
                      _c("el-radio", {
                        attrs: { label: "ZFB" },
                        model: {
                          value: _vm.paymentRadio,
                          callback: function($$v) {
                            _vm.paymentRadio = $$v
                          },
                          expression: "paymentRadio"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("div", { staticClass: "realAmount" }, [
              _c("div", { staticClass: "total" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("total")) + ":")]),
                _vm.realPricing
                  ? _c("span", { staticClass: "content" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            `${_vm.currencyRadio == "CNY" ? "￥" : "$"}${
                              _vm.realPricing.pricing
                            }`
                          ) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.meChatPricing
                ? _c("div", { staticClass: "charges" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("handling_charge")) + ":")
                    ]),
                    _vm.realPricing
                      ? _c("span", { staticClass: "content" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.realPricing.brokerage) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "footer" }, [
              _c(
                "div",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "nextStep",
                  class: _vm.meChatPricing ? "active" : "default",
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.nextStep.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me icon-tubiaozhizuomoban iconStyle"
                  })
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "agreementBox" },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(`(${_vm.$t("agreeRobotAgreement")})`) +
                    "\n        "
                ),
                _c("el-image", {
                  staticStyle: {
                    width: "12px",
                    height: "12px",
                    "margin-left": "6px"
                  },
                  attrs: {
                    src: require("../../../assets/images/meChat/wenhao.png"),
                    fit: "fill"
                  },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.readAgreement.apply(null, arguments)
                    }
                  }
                })
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _vm.componentIndex == 2
      ? _c("div", { staticClass: "finalConfirmation" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.robotInfo.prompts) + "\n    ")
          ]),
          _c("div", { staticClass: "prompts" }, [
            _vm._v("\n      " + _vm._s(_vm.selectedPrompts) + "\n    ")
          ]),
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t("paymentMethod")) + "\n    ")
          ]),
          _c(
            "div",
            { staticClass: "paymentIcon" },
            [
              _c("el-image", {
                style: { width: _vm.payMethod == 7 ? "95px" : "26px" },
                attrs: { src: _vm.paymentIcon, fit: "fill" }
              }),
              _vm.payMethod == 2
                ? _c("span", { staticClass: "paymentTips" }, [
                    _vm._v(_vm._s(_vm.$t("balance")))
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.payMethod != 2
            ? _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("handling_charge")) + "\n    "
                )
              ])
            : _vm._e(),
          _vm.payMethod != 2 && _vm.realPricing
            ? _c("div", { staticClass: "charges" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.realPricing.brokerage) + "\n    "
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "line" }),
          _c("div", { staticClass: "bigTitle" }, [
            _vm._v(_vm._s(_vm.$t("total")) + ":")
          ]),
          _vm.realPricing
            ? _c("div", { staticClass: "totalContent" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      `${_vm.currencyRadio == "CNY" ? "￥" : "$"}${
                        _vm.realPricing.pricing
                      }`
                    ) +
                    "\n    "
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "confirm",
              style: { margin: _vm.payMethod == 2 ? "80px auto" : "40px auto" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.confirm.apply(null, arguments)
                }
              }
            },
            [
              _c("span", {
                staticClass: "iconfont_Me icon-tubiaozhizuomoban iconStyle"
              })
            ]
          ),
          _c(
            "form",
            {
              ref: "payment",
              attrs: {
                action: _vm.payParams.submitUrl,
                method: "post",
                target: "_blank",
                hidden: ""
              }
            },
            _vm._l(_vm.payParams, function(val, key, index) {
              return _c("input", {
                key: index,
                attrs: { type: "text", name: key },
                domProps: { value: val }
              })
            }),
            0
          )
        ])
      : _vm._e(),
    _vm.componentIndex == 3
      ? _c(
          "div",
          { staticClass: "confirmPayment" },
          [_c("PaymentConfirmation", { on: { confirm: _vm.pay_YE } })],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }