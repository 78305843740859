<template>
  <el-form :model="formData" :show-message="false" ref="formData">
    <el-form-item prop="password">
      <div class="inputOuter">
        {{ $t("enterYourPaymentPassword") }}
        <CommonInput
          @inputChange="getAccountText"
          :needInputshowError="inputError"
          :passwordInput="true"
        >
          <span class="iconfont_Me icon-a-lujing13297 iconStyle"></span>
        </CommonInput>
      </div>
    </el-form-item>
    <el-form-item>
      <el-button
        class="submitButton"
        :type="through ? 'primary' : 'info'"
        @click="
          () => {
            if (through) {
              handlerSubmitForm();
            }
          }
        "
      >
        <i class="el-icon--step-next" />
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import CommonInput from "@/newVersion/components/inputComponent/commonInput";
export default {
  name: "PaymentConfirmation",
  data() {
    return {
      formData: {
        password: "",
      },
      through: false,
      inputError: false,
    };
  },
  components: {
    CommonInput,
  },
  methods: {
    async handlerSubmitForm() {
      this.$emit("confirm", this.formData.password);
    },
    // 获取输入的文本
    async getAccountText(text) {
      this.formData.password = text;
      if (!this.formData.password) {
        this.through = false;
        this.inputError = false;
      } else {
        this.through = true;
        this.inputError = false;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.inputOuter
  width 100% !important;
  height 46px;
  margin-top 0px;
.submitButton
  margin 60px 0 0 0;
  width 100%
  height: 46px
::deep .el-button--info
  background-color #E6E6E6 !important;
  border-color #E6E6E6 !important;
</style>
