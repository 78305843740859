var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        center: "",
        "custom-class": "robotChatDescription",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": true,
        top: "0",
        width:
          _vm.componentIndex == 0
            ? "420px"
            : _vm.componentIndex == 1 || _vm.componentIndex == 3
            ? "500px"
            : _vm.componentIndex == 2
            ? "320px"
            : "420px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _vm.componentIndex == 0
        ? [
            _c("div", { staticClass: "closeBox" }, [
              _c("i", {
                staticClass: "icon-x iconfont_Me iconStyle",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closeDialog.apply(null, arguments)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "dialogHeaderContainer" }, [
              _vm._v("\n      " + _vm._s(_vm.dialogTitle) + "\n    ")
            ]),
            _c("div", { staticClass: "dialogContainer" }, [
              _vm.dialogType == "notice"
                ? _c("div", { staticClass: "iconBox" }, [
                    _c("i", {
                      staticClass: "icon-remind iconfont_Me iconStyle"
                    })
                  ])
                : _vm._e(),
              _c("div", { staticClass: "contentBox" }, [
                _c("div", { staticClass: "inner" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.dialogContent) + "\n          "
                  ),
                  _vm.dialogType == "default"
                    ? _c(
                        "p",
                        {
                          staticClass: "introductionVideo",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.playIntroductionVideo.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("introduction")) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ]),
            _c(
              "div",
              { staticClass: "dialogFooter" },
              [
                _vm.dialogType == "default"
                  ? [
                      _vm.userInfo && _vm.userInfo.vipStatus
                        ? [
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                staticClass: "commonButton active",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.nextStep.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "iconfont_Me icon-tubiaozhizuomoban iconStyle"
                                })
                              ]
                            )
                          ]
                        : [
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                staticClass: "commonButton active",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.premiumMembership.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("premiumMembership")) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                directives: [
                                  { name: "waves", rawName: "v-waves" }
                                ],
                                staticClass: "commonButton active",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.buyMeChat.apply(null, arguments)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.replaceMechati18n(_vm.$t("buyMeChat"))
                                    ) +
                                    "\n          "
                                )
                              ]
                            )
                          ]
                    ]
                  : _vm._e(),
                _vm.dialogType == "notice"
                  ? [
                      _c(
                        "div",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "commonButton active",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.goBuyQuestions.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-next iconfont_Me iconStyle"
                          })
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        : _vm._e(),
      _vm.componentIndex == 1 ||
      _vm.componentIndex == 2 ||
      _vm.componentIndex == 3
        ? _c("PaymentModule", {
            ref: "paymentModule",
            attrs: { componentIndex: _vm.componentIndex },
            on: {
              close: _vm.closeDialog,
              changeComponentIndex: _vm.changeComponentIndex
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }