export default {
  watch: {
    "$store.state.loginAndRegister.loginAndRegisterDialog": {
      handler(val, old) {
        if (val && val != old) {
          this.closeDialog()
        }
      },
      deep: true,
    },
  },
}