<template>
  <el-dialog
    center
    custom-class="robotChatDescription"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :append-to-body="true"
    :top="'0'"
    :width="
      componentIndex == 0
        ? '420px'
        : componentIndex == 1 || componentIndex == 3
        ? '500px'
        : componentIndex == 2
        ? '320px'
        : '420px'
    "
  >
    <template v-if="componentIndex == 0">
      <div class="closeBox">
        <i class="icon-x iconfont_Me iconStyle" @click.stop="closeDialog"></i>
      </div>
      <div class="dialogHeaderContainer">
        {{ dialogTitle }}
      </div>
      <div class="dialogContainer">
        <div class="iconBox" v-if="dialogType == 'notice'">
          <i class="icon-remind iconfont_Me iconStyle"></i>
        </div>
        <div class="contentBox">
          <div class="inner">
            {{ dialogContent }}
            <p
              class="introductionVideo"
              @click.stop="playIntroductionVideo"
              v-if="dialogType == 'default'"
            >
              {{ $t("introduction") }}
            </p>
          </div>
        </div>
      </div>
      <div class="dialogFooter">
        <template v-if="dialogType == 'default'">
          <template v-if="userInfo && userInfo.vipStatus">
            <div class="commonButton active" v-waves @click.stop="nextStep">
              <span class="iconfont_Me icon-tubiaozhizuomoban iconStyle"></span>
            </div>
          </template>
          <template v-else>
            <div
              class="commonButton active"
              v-waves
              @click.stop="premiumMembership"
            >
              {{ $t("premiumMembership") }}
            </div>
            <div class="commonButton active" v-waves @click.stop="buyMeChat">
              {{ replaceMechati18n($t("buyMeChat")) }}
            </div>
          </template>
        </template>
        <template v-if="dialogType == 'notice'">
          <div class="commonButton active" v-waves @click.stop="goBuyQuestions">
            <i class="icon-next iconfont_Me iconStyle"></i>
          </div>
        </template>
      </div>
    </template>
    <PaymentModule
      v-if="componentIndex == 1 || componentIndex == 2 || componentIndex == 3"
      ref="paymentModule"
      @close="closeDialog"
      :componentIndex="componentIndex"
      @changeComponentIndex="changeComponentIndex"
    />
  </el-dialog>
</template>
<script>
import PaymentModule from "./paymentModule.vue";
import mixins from "../../mixins/dialog";
import * as RESTApi from "@/api/rest";
import Bus from "@/utils/bus.js";
export default {
  name: "RobotChatDescription",
  mixins: [mixins],
  data() {
    return {
      timer: null,
      componentIndex: 0,
      buttonType: 0,
    };
  },
  components: {
    PaymentModule,
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    robotInfo() {
      return this.$store.state.robot.robotInfo;
    },
    robotChatDescription() {
      return this.$store.state.globalShare.robotChatDescription;
    },
    dialogVisible() {
      return this.robotChatDescription.visible;
    },
    dialogType() {
      return this.robotChatDescription && this.robotChatDescription.type
        ? this.robotChatDescription.type
        : "";
    },
    dialogTitle() {
      let dialogTitle = "";
      switch (this.dialogType) {
        case "default":
          dialogTitle = this.$t("newFunction");
          break;
        case "notice":
          dialogTitle = this.$t("notice");
          break;
        default:
          dialogTitle = "";
          break;
      }
      return dialogTitle;
    },
    dialogContent() {
      let dialogContent = "";
      switch (this.dialogType) {
        case "default":
          dialogContent = this.replaceMechati18n(this.$t("robotDescription"));
          break;
        case "notice":
          dialogContent = this.$t("robotNotice");
          break;
        default:
          dialogContent = "";
          break;
      }
      return dialogContent;
    },
  },
  mounted() {
    switch (this.dialogType) {
      case "buyQuestions":
        this.componentIndex = 1;
        this.$nextTick(async () => {
          await this.$refs.paymentModule.getMeChatPrompts();
          await this.$refs.paymentModule.checkBalance();
        });
        break;
      default:
        this.componentIndex = 0;
        break;
    }
    Bus.$on("agreeToTheRobotAgreement", async (msg) => {
      await RESTApi.updateProcessStatus("2");
      this.$store.commit("setAgrementForMechat", false);
      if (this.buttonType == 1) {
        this.goBuyQuestions();
      } else {
        this.closeDialog();
        this.$openMechat();
      }
    });
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    Bus.$off("agreeToTheRobotAgreement");
  },
  methods: {
    // 下一步
    nextStep() {
      this.buttonType = 2;
      this.$store.commit("setAgrementForMechat", true);
    },
    // 购买问答次数
    async buyMeChat() {
      this.securityVerification(async () => {
        if (this.robotInfo.processStatus == 0) {
          await RESTApi.updateProcessStatus("1");
        }
        this.buttonType = 1;
        this.$store.commit("setAgrementForMechat", true);
      }, false);
    },
    // 充值会员
    async premiumMembership() {
      this.touristProcess().then(async () => {
        if (this.robotInfo.processStatus == 0) {
          await RESTApi.updateProcessStatus("1");
        }
        this.handleClickVip();
      });
    },
    changeComponentIndex(index) {
      this.componentIndex = index;
      if (index == 1) {
        this.$nextTick(async () => {
          await this.$refs.paymentModule.getMeChatPrompts();
          await this.$refs.paymentModule.checkBalance();
        });
      }
    },
    closeDialog() {
      const robotChatDescription = this.robotChatDescription;
      robotChatDescription.visible = false;
      robotChatDescription.type = "default";
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.$store.commit("setRobotChatDescription", robotChatDescription);
    },
    playIntroductionVideo() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      const language = localStorage.getItem("langCode") || 1;
      let videoUrl;
      if (language == 1) {
        videoUrl = "https://video.melinked.com/MeChat_web_CN.mp4";
      } else {
        videoUrl = "https://video.melinked.com/MeChat_web_EN.mp4";
      }
      this.$alert(
        <video src={videoUrl} autoplay controls style="max-height:800px;" />,
        this.alertTitle,
        {
          customClass: [
            "me-video-alert",
            !this.alertTitle && "me-video-alert--notitle",
          ],
          closeOnClickModal: true,
          showConfirmButton: false,
          beforeClose: (action, instance) => {
            const video = instance.$el.querySelector("video");
            video.pause();
            video.currentTime = 0;
            instance.close();
          },
          callback: () => {},
        }
      );
      this.timer = setTimeout(() => {
        const video = document.querySelector(".me-video-alert video");
        video.play();
      }, 200);
    },
    goBuyQuestions() {
      this.componentIndex = 1;
      this.$nextTick(async () => {
        await this.$refs.paymentModule.getMeChatPrompts();
        await this.$refs.paymentModule.checkBalance();
      });
    },
    // 游客流程
    touristProcess() {
      return new Promise((resolve, reject) => {
        if (this.userInfo.loginState == "touristLogin") {
          this.$store.commit("setTouristTips", true);
          reject();
        } else {
          resolve();
        }
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.robotChatDescription
  .closeBox
    width: 100%;
    height: 24px;
    margin-top: 12px;
    text-align: right;
    .iconStyle
      font-size: 24px;
      color: #CFCFCF;
      font-weight: 500;
      margin-right: 13px;
      cursor pointer;
  .dialogHeaderContainer
    width: 100%;
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding:8px 25px 30px 25px;
  .dialogContainer
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 45px  20px 45px;
    .iconBox
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .iconStyle
        font-size: 18px;
        color #FF8A00;
        font-weight: bold;
    .contentBox
      flex: 1;
      min-width: 0;
      overflow: hidden;
      color: #000000;
      font-size: 16px;
      line-height: 30px;
      word-break: normal;
      display: flex;
      align-items: flex-start;
      .iconBox
        width: 20px;
        flex-shrink: 0;
        margin-right: 10px;
        .iconStyle
          color: #FF8A00;
          font-size: 20px;
      .inner
        flex: 1;
        min-width: 0;
        overflow hidden;
      .introductionVideo
        color: #33CC66;
        cursor pointer;
  .dialogFooter
    width: 240px;
    margin: auto;
    overflow: hidden;
    padding-bottom: 50px;
    .commonButton
      width: 100%;
      height: 34px;
      border-radius: 5px;
      margin-top: 10px;
      text-align: center;
      line-height: 34px;
      font-size: 15px;
      cursor pointer;
      font-weight: 700;
      user-select none;
      .iconStyle
        font-size: 20px;
        color:#FFFFFF
    .active
      color: #FFFFFF;
      background: #33CC66;
    .default
      color: #666666;
      background: #D9D9D9;
</style>
<style lang="stylus">
.robotChatDescription
  position: relative;
  border-radius: 15px;
  overflow hidden;
  transition: width 0.3s, height 0.3s;
  .el-dialog__header
    display: none;
  .el-dialog__body
    padding 0;
</style>
